const DEFAULT_LOCALES = "en"
const SUPPORTED_LOCALES = [
    {
        code: "en",
        base: "/en",
        name: "English",
        translations: "/locales/en-hk.json"
    },
    {
        code: "tc",
        base: "/tc",
        name: "中文",
        translations: "/locales/zh-hk.json"
    }
]

export default {
    DEFAULT_LOCALES,
    SUPPORTED_LOCALES
}

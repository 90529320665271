<template>
    <div class="flex flex-row">
        <router-view v-if="!isLoading" />
    </div>
</template>

<script>
import { getSavedLocale } from "@/util/locate"
import axios from "axios"

export default {
    data() {
        return {
            user: this.$auth0.user,
            isAuthenticated: this.$auth0.isAuthenticated,
            isLoading: this.$auth0.isLoading
        }
    },
    watch: {
        $route(to, from, next) {
            document.title = `${to.meta.title}`
        }
    },
    methods: {
        setViewHeight() {
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty("--vh", `${vh}px`)
        }
    },
    async mounted() {
        this.setViewHeight()
        window.addEventListener("resize", () => {
            this.setViewHeight()
        })
    }
}
</script>

<style></style>

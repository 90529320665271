import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import i18n from "@/plugins/i18n"
import "./styles/index.css"
import axios from "axios"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import { createAuth0 } from "@auth0/auth0-vue"

async function init() {
    const app = createApp(App)

    Sentry.init({
        app,
        dsn: "https://921bf1b8d5d749d5bcd6f57d4919382f@o510694.ingest.sentry.io/6592931",
        environment: process.env.VUE_APP_ENVIRONMENT,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [
                    "localhost",
                    process.env.VUE_APP_API_BASE_URL,
                    /^\//
                ]
            })
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    })

    const rootComponent = app
        .use(router)
        .use(
            createAuth0({
                domain: process.env.VUE_APP_AUTH0_DOMAIN,
                clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
                authorizationParams: {
                    redirect_uri: window.location.origin,
                    audience: process.env.VUE_APP_AUTH0_AUDIENCE
                }
            })
        )
        .use(i18n)
        .mount("#app")

    app.config.globalProperties.$axios = axios
    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            let errorResponse = ""
            if (error.response && error.response.data) {
                errorResponse = error.response.data
            } else if (error.request) {
                errorResponse =
                    error.request.message || error.request.statusText
            } else {
                errorResponse = error.message
            }
            // if (error.response === undefined) {
            //     alert(
            //         "This could be a CORS issue or a dropped internet connection. " +
            //             "Please refresh and try again."
            //     )
            // }
            return Promise.reject(error)
        }
    )
}

init()

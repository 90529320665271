import { createI18n } from "vue-i18n"
import tc from "@/i18n/zh-hk.json"
import en from "@/i18n/en-us.json"

import { Locale } from "@/constants"

const i18n = createI18n({
    locale: Locale.DEFAULT_LOCALES,
    messages: {
        tc: tc,
        en: en
    },
    fallbackLocale: Locale.DEFAULT_LOCALES
})

export default i18n
